import StoreIds from '~/stores/storeIds';
import { defineStore } from 'pinia';

const useStore = defineStore(StoreIds.PREVENT_SCROLL, {
  state: () => ({
    sources: 0,
  }),
  actions: {
    preventScroll() {
      this.sources++;
      this.check();
    },
    allowScroll() {
      if (this.sources > 0) this.sources--;
      this.check();
    },
    check() {
      if (this.sources) {
        document.querySelector('html').classList.add('prevent-scroll');
      } else {
        document.querySelector('html').classList.remove('prevent-scroll');
      }
    },
  },
});

export function usePreventBodyScroll(preventScroll: boolean) {
  const s = useStore();
  if (import.meta.client) {
    if (preventScroll) {
      s.preventScroll();
    } else {
      s.allowScroll();
    }
  }
}
